export default class TabsEnum {
    static ADDNEWJOB = [
        {
            title: 'Add Files',
            description: 'Please upload all files that need to be retouched',
        },
        {
            title: 'Add Requirements',
            description: 'Maximize your project\'s success by sharing all relevant details with your expert re-toucher!',
        },
        {
            title: 'Add Due Date',
            description: 'Please choose a completion date and time for your project',
        }
    ];

    static INDEXES = {
        add_file: 0,
        add_requirements: 1,
        add_due_date: 2,
    };
}
