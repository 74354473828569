<template>
    <div>
        <div v-if="this.is_preview === false">
            <button v-if="!window_desktop" class="text-center upload_button item_image" @click="chooseDropboxSource">
                <svg fill="black" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="130" height="130"><path d="M 15.5 1 C 15.34375 1.015625 15.195313 1.070313 15.0625 1.15625 L 0.46875 10.5625 C 0.199219 10.738281 0.03125 11.035156 0.0195313 11.359375 C 0.0078125 11.679688 0.152344 11.988281 0.40625 12.1875 L 9.34375 18.96875 L 0.40625 25.6875 C 0.128906 25.886719 -0.03125 26.214844 -0.0117188 26.558594 C 0.0078125 26.898438 0.199219 27.207031 0.5 27.375 L 9 32.375 L 9 39.09375 C 8.996094 39.429688 9.160156 39.746094 9.4375 39.9375 L 24.4375 49.84375 C 24.777344 50.074219 25.222656 50.074219 25.5625 49.84375 L 40.5625 40.03125 C 40.839844 39.839844 41.003906 39.523438 41 39.1875 L 41 32.4375 L 49.5 27.34375 C 49.769531 27.179688 49.945313 26.898438 49.976563 26.585938 C 50.007813 26.273438 49.890625 25.960938 49.65625 25.75 L 41.25 18.625 L 49.625 11.78125 C 49.875 11.578125 50.015625 11.265625 49.996094 10.945313 C 49.976563 10.621094 49.804688 10.328125 49.53125 10.15625 L 35.34375 1.15625 C 35.140625 1.023438 34.898438 0.96875 34.65625 1 C 34.472656 1.023438 34.300781 1.101563 34.15625 1.21875 L 25.03125 8.8125 L 16.25 1.25 C 16.046875 1.066406 15.773438 0.976563 15.5 1 Z M 15.53125 3.25 L 23.34375 9.96875 L 11.03125 17.78125 L 2.75 11.46875 Z M 34.875 3.25 L 47.28125 11.09375 L 39.59375 17.375 L 26.75 9.9375 Z M 25 11.28125 L 37.75 18.625 L 24.96875 26.625 L 12.875 19 Z M 39.59375 19.8125 L 47.3125 26.375 L 39.6875 30.9375 L 39.59375 30.96875 C 39.503906 31.007813 39.417969 31.0625 39.34375 31.125 L 34.6875 33.90625 L 26.75 27.875 Z M 11.0625 20.21875 L 23.25 27.875 L 15.53125 33.90625 L 2.8125 26.40625 Z M 25.03125 29.0625 L 34 35.90625 C 34.328125 36.15625 34.773438 36.179688 35.125 35.96875 L 39 33.65625 L 39 38.65625 L 25 47.78125 L 11 38.53125 L 11 33.5625 L 15.09375 35.96875 C 15.453125 36.171875 15.898438 36.136719 16.21875 35.875 Z"/></svg>
                <div class="text-center description_mobile main_text">
                    Dropbox
                </div>
            </button>
            <button v-else class="text-center upload_button item_image" @click="chooseDropboxSource">
                <svg fill="black" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="130" height="130"><path d="M 15.5 1 C 15.34375 1.015625 15.195313 1.070313 15.0625 1.15625 L 0.46875 10.5625 C 0.199219 10.738281 0.03125 11.035156 0.0195313 11.359375 C 0.0078125 11.679688 0.152344 11.988281 0.40625 12.1875 L 9.34375 18.96875 L 0.40625 25.6875 C 0.128906 25.886719 -0.03125 26.214844 -0.0117188 26.558594 C 0.0078125 26.898438 0.199219 27.207031 0.5 27.375 L 9 32.375 L 9 39.09375 C 8.996094 39.429688 9.160156 39.746094 9.4375 39.9375 L 24.4375 49.84375 C 24.777344 50.074219 25.222656 50.074219 25.5625 49.84375 L 40.5625 40.03125 C 40.839844 39.839844 41.003906 39.523438 41 39.1875 L 41 32.4375 L 49.5 27.34375 C 49.769531 27.179688 49.945313 26.898438 49.976563 26.585938 C 50.007813 26.273438 49.890625 25.960938 49.65625 25.75 L 41.25 18.625 L 49.625 11.78125 C 49.875 11.578125 50.015625 11.265625 49.996094 10.945313 C 49.976563 10.621094 49.804688 10.328125 49.53125 10.15625 L 35.34375 1.15625 C 35.140625 1.023438 34.898438 0.96875 34.65625 1 C 34.472656 1.023438 34.300781 1.101563 34.15625 1.21875 L 25.03125 8.8125 L 16.25 1.25 C 16.046875 1.066406 15.773438 0.976563 15.5 1 Z M 15.53125 3.25 L 23.34375 9.96875 L 11.03125 17.78125 L 2.75 11.46875 Z M 34.875 3.25 L 47.28125 11.09375 L 39.59375 17.375 L 26.75 9.9375 Z M 25 11.28125 L 37.75 18.625 L 24.96875 26.625 L 12.875 19 Z M 39.59375 19.8125 L 47.3125 26.375 L 39.6875 30.9375 L 39.59375 30.96875 C 39.503906 31.007813 39.417969 31.0625 39.34375 31.125 L 34.6875 33.90625 L 26.75 27.875 Z M 11.0625 20.21875 L 23.25 27.875 L 15.53125 33.90625 L 2.8125 26.40625 Z M 25.03125 29.0625 L 34 35.90625 C 34.328125 36.15625 34.773438 36.179688 35.125 35.96875 L 39 33.65625 L 39 38.65625 L 25 47.78125 L 11 38.53125 L 11 33.5625 L 15.09375 35.96875 C 15.453125 36.171875 15.898438 36.136719 16.21875 35.875 Z"/></svg>
                <div class="text-center description_mobile main_text">
                    Dropbox
                </div>
            </button>
        </div>
        <div v-if="this.is_preview === true">
            <button v-if="!window_desktop" class="text-center item_image" @click="chooseDropboxSource">
                <svg fill="black" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="130" height="130"><path d="M 15.5 1 C 15.34375 1.015625 15.195313 1.070313 15.0625 1.15625 L 0.46875 10.5625 C 0.199219 10.738281 0.03125 11.035156 0.0195313 11.359375 C 0.0078125 11.679688 0.152344 11.988281 0.40625 12.1875 L 9.34375 18.96875 L 0.40625 25.6875 C 0.128906 25.886719 -0.03125 26.214844 -0.0117188 26.558594 C 0.0078125 26.898438 0.199219 27.207031 0.5 27.375 L 9 32.375 L 9 39.09375 C 8.996094 39.429688 9.160156 39.746094 9.4375 39.9375 L 24.4375 49.84375 C 24.777344 50.074219 25.222656 50.074219 25.5625 49.84375 L 40.5625 40.03125 C 40.839844 39.839844 41.003906 39.523438 41 39.1875 L 41 32.4375 L 49.5 27.34375 C 49.769531 27.179688 49.945313 26.898438 49.976563 26.585938 C 50.007813 26.273438 49.890625 25.960938 49.65625 25.75 L 41.25 18.625 L 49.625 11.78125 C 49.875 11.578125 50.015625 11.265625 49.996094 10.945313 C 49.976563 10.621094 49.804688 10.328125 49.53125 10.15625 L 35.34375 1.15625 C 35.140625 1.023438 34.898438 0.96875 34.65625 1 C 34.472656 1.023438 34.300781 1.101563 34.15625 1.21875 L 25.03125 8.8125 L 16.25 1.25 C 16.046875 1.066406 15.773438 0.976563 15.5 1 Z M 15.53125 3.25 L 23.34375 9.96875 L 11.03125 17.78125 L 2.75 11.46875 Z M 34.875 3.25 L 47.28125 11.09375 L 39.59375 17.375 L 26.75 9.9375 Z M 25 11.28125 L 37.75 18.625 L 24.96875 26.625 L 12.875 19 Z M 39.59375 19.8125 L 47.3125 26.375 L 39.6875 30.9375 L 39.59375 30.96875 C 39.503906 31.007813 39.417969 31.0625 39.34375 31.125 L 34.6875 33.90625 L 26.75 27.875 Z M 11.0625 20.21875 L 23.25 27.875 L 15.53125 33.90625 L 2.8125 26.40625 Z M 25.03125 29.0625 L 34 35.90625 C 34.328125 36.15625 34.773438 36.179688 35.125 35.96875 L 39 33.65625 L 39 38.65625 L 25 47.78125 L 11 38.53125 L 11 33.5625 L 15.09375 35.96875 C 15.453125 36.171875 15.898438 36.136719 16.21875 35.875 Z"/></svg>
                <div class="text-center description_mobile main_text">
                    Dropbox
                </div>
            </button>
            <button v-else class="text-center item_image" @click="chooseDropboxSource">
                <svg fill="black" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="130" height="130"><path d="M 15.5 1 C 15.34375 1.015625 15.195313 1.070313 15.0625 1.15625 L 0.46875 10.5625 C 0.199219 10.738281 0.03125 11.035156 0.0195313 11.359375 C 0.0078125 11.679688 0.152344 11.988281 0.40625 12.1875 L 9.34375 18.96875 L 0.40625 25.6875 C 0.128906 25.886719 -0.03125 26.214844 -0.0117188 26.558594 C 0.0078125 26.898438 0.199219 27.207031 0.5 27.375 L 9 32.375 L 9 39.09375 C 8.996094 39.429688 9.160156 39.746094 9.4375 39.9375 L 24.4375 49.84375 C 24.777344 50.074219 25.222656 50.074219 25.5625 49.84375 L 40.5625 40.03125 C 40.839844 39.839844 41.003906 39.523438 41 39.1875 L 41 32.4375 L 49.5 27.34375 C 49.769531 27.179688 49.945313 26.898438 49.976563 26.585938 C 50.007813 26.273438 49.890625 25.960938 49.65625 25.75 L 41.25 18.625 L 49.625 11.78125 C 49.875 11.578125 50.015625 11.265625 49.996094 10.945313 C 49.976563 10.621094 49.804688 10.328125 49.53125 10.15625 L 35.34375 1.15625 C 35.140625 1.023438 34.898438 0.96875 34.65625 1 C 34.472656 1.023438 34.300781 1.101563 34.15625 1.21875 L 25.03125 8.8125 L 16.25 1.25 C 16.046875 1.066406 15.773438 0.976563 15.5 1 Z M 15.53125 3.25 L 23.34375 9.96875 L 11.03125 17.78125 L 2.75 11.46875 Z M 34.875 3.25 L 47.28125 11.09375 L 39.59375 17.375 L 26.75 9.9375 Z M 25 11.28125 L 37.75 18.625 L 24.96875 26.625 L 12.875 19 Z M 39.59375 19.8125 L 47.3125 26.375 L 39.6875 30.9375 L 39.59375 30.96875 C 39.503906 31.007813 39.417969 31.0625 39.34375 31.125 L 34.6875 33.90625 L 26.75 27.875 Z M 11.0625 20.21875 L 23.25 27.875 L 15.53125 33.90625 L 2.8125 26.40625 Z M 25.03125 29.0625 L 34 35.90625 C 34.328125 36.15625 34.773438 36.179688 35.125 35.96875 L 39 33.65625 L 39 38.65625 L 25 47.78125 L 11 38.53125 L 11 33.5625 L 15.09375 35.96875 C 15.453125 36.171875 15.898438 36.136719 16.21875 35.875 Z"/></svg>
                <div class="text-center description_mobile main_text">
                    Dropbox
                </div>
            </button>
        </div>
        <div class="modal fade modal-google-drive pb-5" ref="dropbox_modal" id="google-drive-files-list" tabindex="-1" aria-labelledby="google-drive-files-list" aria-hidden="true">
            <div class="modal-dialog modal-google-drive-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button ref="folder_back" type="button" data-previous-folder="/" class="btn-folder-back" aria-label="Back" @click="backClick">
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 26.676 26.676" style="enable-background:new 0 0 26.676 26.676;" xml:space="preserve">
                            <g><path d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59 c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815 C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029 c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562 C26.18,21.891,26.141,21.891,26.105,21.891z"/>
                                <g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/>
                            </svg>
                        </button>
                        <h5 class="modal-title red_color" id="errorModalLabel">Dropbox Files List</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body modal-google-drive-body">
                        <div class="row gd-item-row"
                             v-for="(dropboxfile, key, index) in dropbox_file_list"
                             :data-file-name="index"
                             :data-file-display-path="dropboxfile.display_path"
                             :class = "(dropboxfile.tag === 'folder') ? 'directory-row' : 'image-row'"
                             :key="key"
                             @click="() => (dropboxfile.tag === 'folder') ? folderClick(dropboxfile.path_display) : imageClick(dropboxfile.path_display)">
                            <div class="col-1 d-flex justify-content-center">
                                <svg v-if="dropboxfile.tag === 'folder'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-folder2" viewBox="0 0 16 16">
                                    <path d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v7a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 12.5v-9zM2.5 3a.5.5 0 0 0-.5.5V6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5zM14 7H2v5.5a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5V7z"/>
                                </svg>
                                <svg v-if="dropboxfile.tag === 'file'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-image-fill" viewBox="0 0 16 16">
                                    <path d="M4 0h8a2 2 0 0 1 2 2v8.293l-2.73-2.73a1 1 0 0 0-1.52.127l-1.889 2.644-1.769-1.062a1 1 0 0 0-1.222.15L2 12.292V2a2 2 0 0 1 2-2zm4.002 5.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z"/>
                                    <path d="M10.564 8.27 14 11.708V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-.293l3.578-3.577 2.56 1.536 2.426-3.395z"/>
                                </svg>
                            </div>
                            <div class="col-11">
                                <p>{{dropboxfile.name}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button v-if="this.is_authorized" @click="detachDropbox" type="button" class="btn btn-danger">Detach Dropbox</button>
                        <button v-else @click="isDropboxAuth" type="button" class="btn btn-primary">Attach Dropbox</button>
                        <button type="button" class="close_modal main_text" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Modal} from "bootstrap";
import {mapMutations} from "vuex";
import helpFunctions from "../../helpFunctions";
const axios = require('axios');

export default {
    name: 'AddDropboxFile',
    props: [
        "window_desktop",
        "is_preview",
    ],
    data() {
        return {
            dropbox_file_list: {},
            dropbox_modal: null,
            folder_back: null,
            files: [],
            is_authorized: false,
        }
    },
    mounted() {
        this.dropbox_modal = new Modal(this.$refs.dropbox_modal);
        addEventListener('message', this.postmessage);
    },
    unmounted() {
        removeEventListener('message', this.postmessage)
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
        ]),
        postmessage(e) {
            if (e.data === 'Dropbox authorization') {
                this.isDropboxAuth();
            }
        },
        emptyModal() {
            this.dropbox_file_list = "";
        },
        detachDropbox() {
            this.showLoader();
            this.$http.getAuth(`${this.$http.apiUrl()}dropbox/detach`)
                .then( response => {
                    this.is_authorized = false;
                    this.emptyModal();
                })
                .finally(() => this.hideLoader());
        },
        isDropboxAuth() {
            this.$http.getAuth(`${this.$http.apiUrl()}dropbox/is_authorized`)
                .then(response => {
                    let redirectUrl = response?.data;
                    if (typeof redirectUrl === 'string' && redirectUrl.includes("oauth2")) {
                        window.open(redirectUrl, "_blank");
                    } else if(redirectUrl == true) {
                        this.is_authorized = true;
                        this.folderClick("/");
                    }
                });
        },
        backClick() {
            let folder_back_obj = this.$refs.folder_back;
            let previous_folder = folder_back_obj.getAttribute("data-previous-folder");
            this.folderClick(previous_folder);
        },
        folderClick(file_path) {
            file_path = file_path === "/" || file_path === "" ? "" : file_path;
            let previous_folder = file_path.substring(0, file_path.lastIndexOf('/'));
            let folder_back_obj = this.$refs.folder_back;
            folder_back_obj.setAttribute("data-previous-folder", previous_folder);
            this.showLoader();
            this.$http.getAuth(`${this.$http.apiUrl()}dropbox/files/list?path=${file_path}`)
                .then(response => {
                        this.dropbox_file_list = Object.assign({}, response.data);
                    }
                )
                .finally(() => this.hideLoader());
        },
        imageClick: function(file_path) {
            this.showLoader();
            axios.get(`${this.$http.apiUrl()}dropbox/files/get?path=${file_path}`,
                {
                    headers: { 'Authorization': `Bearer ${localStorage.getItem('strobeart_jwt')}` },
                    responseType: 'blob'
                })
                .then(response => {
                    let content_type = response.headers["content-type"];
                    let extension = content_type.split("/")[1];
                    let image_name = helpFunctions.makeId(24);
                    let file = new File([response.data], `${image_name}.${extension}`);

                    const reader = new FileReader();
                    reader.onload = e => {
                        let src;
                        if (file.type.toLowerCase()==='image/tiff' || file.type.toLowerCase()==='image/tif') {
                            try {
                                let tiff = new Tiff({buffer: reader.result});
                                let canvas = tiff.toCanvas();
                                src = canvas.toDataURL();
                            } catch (e) {
                                src = 'tif';
                            }
                        }
                        else if (file.type.toLowerCase()==='image/gif' || file.type.toLowerCase()==='image/webp' || file.type.toLowerCase()==='image/bmp' ||
                            file.type.toLowerCase()==='image/jpeg' || file.type.toLowerCase()==='image/jpg' || file.type.toLowerCase()==='image/png') {
                            src = reader.result;
                        }
                        else {
                            src = "";
                        }
                        this.$emit("include_image",
                            {
                                "image": {
                                    src: src,
                                    name: file.name,
                                },
                                "file": file,
                            });
                    }
                    if (file.type.toLowerCase()==='image/tiff' || file.type.toLowerCase()==='image/tif') {
                        reader.readAsArrayBuffer(file);
                    }
                    else {
                        reader.readAsDataURL(file);
                    }

                    /*const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = e => {
                        this.$emit("include_image",
                            {
                                "image": {
                                    src: reader.result,
                                    name: file.name,
                                },
                                "file": file,
                            });
                    }*/
                })
                .then(response => {
                    this.dropbox_modal.toggle();
                })
                .finally(() => this.hideLoader());
        },
        chooseDropboxSource () {
            this.isDropboxAuth();
            if(this.dropbox_modal) {
                this.dropbox_modal.toggle();
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.main_text {
    color: #494949;
    font-style: normal;
}
.gd-item-row {
    cursor: pointer;
}
.item_image {
    width: 102px;
    height: 102px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3px;
    margin: 3px 0;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15) !important;
    box-sizing: border-box;
    border-radius: 5px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .delete_image_button {
        position: absolute;
        top: 0;
        right: 0;
        background: red;
        color: white;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: none;
        padding-bottom: 3px;

        span {
            position: absolute;
            top: 1px;
            right: 7px;
            font-style: normal;
        }
    }
}
.description_mobile {
    font-weight: 500;
    font-size: 12.5px;
    line-height: 20px;
}
.upload_button {
    width: 249px;
    height: 272px;
    background: white;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15) !important;
    border: none;
    margin: 0 auto;
    @media only screen and (max-width: 992px) {
        margin-top: 15px;
        margin-bottom: 15px;
        width: 120px;
        height: 120px;
    }
}
.btn-folder-back {
    background-color: #e0e0e0;
    border-radius: 30px;
    border: none;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    margin-right: 20px;
}
.close_modal {
    width: 120px;
    height: 50px;
    background: white;
    border: 0.5px solid #494949;
    box-sizing: border-box;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
}
</style>
