<template>
    <div class="item_body">
        <div class="row">
            <div class="col-lg-5 col-12">
                <div class="row input_lines align-items-center">
                    <div class="col-4 main_text label_title">
                        Editing Level
                        <span class="red_color star">*</span>
                    </div>
                    <div class="col-6 required_select"  @click="dropError">
                        <multiselect v-model="requirements.editing_level"
                                     :options="editing_levels"
                                     :class="{ 'is-invalid': errors.editing_level }"
                                     selectLabel="" deselectLabel="" :show-labels="false"
                                     placeholder="Select Editing Level"/>
                        <div id="editingLevelFeedback" class="invalid-feedback">
                            Please choose editing level.
                        </div>
                    </div>
                </div>
                <div class="row input_lines align-items-center" title="Create a style guide to autofill job posting parameters and to provide detailed editing instructions and guidelines for similar projects">
                    <div class="col-4 main_text label_title">
                        Style Guide (<router-link :to="{name: 'Jobs', query: { tab: 'style-guides' }}">Add</router-link>)
                    </div>
                    <div class="col-6 required_select">
                        <multiselect v-model="requirements.style_guide"
                                     :options="style_guides"
                                     label="name"
                                     selectLabel="" deselectLabel="" :show-labels="false"
                                     placeholder="Select Style Guide"/>
                    </div>
                </div>
                <div class="row input_lines align-items-center">
                    <div class="col-4 main_text label_title">
                        Color Profile
                        <span class="red_color star">*</span>
                    </div>
                    <div class="col-6 required_select">
                        <multiselect v-model="requirements.color_profile"
                                     :options="color_profiles"
                                     :class="{ 'is-invalid': errors.color_profile }"
                                     selectLabel="" deselectLabel="" :show-labels="false"
                                     placeholder="Select Color Profile"/>
                        <div id="colorProfileFeedback" class="invalid-feedback">
                            Please choose a color profile.
                        </div>
                    </div>
                </div>
                <div class="row input_lines align-items-center">
                    <div class="col-4 main_text label_title">
                        File Format
                        <span class="red_color star">*</span>
                    </div>
                    <div class="col-6 required_select">
                        <multiselect v-model="requirements.file_format"
                                     :options="file_formats"
                                     :class="{ 'is-invalid': errors.file_format }"
                                     selectLabel="" deselectLabel=""
                                     :multiple="true"
                                     :close-on-select="false"
                                     placeholder="Select File Format"/>
                        <div id="fileFormatFeedback" class="invalid-feedback">
                            Please choose a file format.
                        </div>
                    </div>
                </div>
                <div class="row input_lines align-items-start">
                    <div class="col-4 main_text label_title">
                        Sizes
                    </div>
                    <div class="col-6 required_select">
                        <div v-for="(size, index) in requirements.sizes" class="d-flex justify-content-between mb-3">
                            <div class="size-block">
                                <span class="d-flex flex-row">
                                    <input type="text" class="form-control style_input1 with_input1" placeholder="300" v-model="requirements.sizes[index].width">
                                    <div class="x">x</div>
                                    <input type="text" class="form-control style_input1 with_input1" placeholder="300" v-model="requirements.sizes[index].height">
                                </span>
                                <span class="d-flex flex-row align-items-center mt-2">
                                    <input type="text" class="form-control style_input1 with_input1" placeholder="px" v-model="requirements.sizes[index].unit">
                                    <button class="btn btn-danger ms-2" @click="removeSize(index)">-</button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 offset-4">
                        <button class="btn btn-primary w-100" @click="addSize">Add</button>
                    </div>
                </div>
                <div class="row input_lines align-items-center mt-4">
                    <div class="col-4 main_text label_title">
                        Other
                    </div>
                    <div class="col-6">
                        <input type="text" class="input_text_stile" placeholder="Add pink background..." v-model="requirements.other">
                    </div>
                </div>
                <div class="row input_lines align-items-center">
                    <div class="col-4 main_text label_title">
                        Job name
                    </div>
                    <div class="col-6">
                        <input type="text" class="input_text_stile" placeholder="Enter Project Name" v-model="requirements.name">
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-12">
                <div class="row input_lines align-items-center">

                    <div class="col-6 main_text label_title">
                        Add Logo
                        <span @click="openModalAddFile" class="upload-files-text">(Add new file)</span>
                    </div>
                    <div class="col-6 required_select">
                        <multiselect v-model="requirements.add_logo"
                                     track-by="id" label="id" :options="user_files"
                                     :custom-label="customLabel"
                                     selectLabel="" deselectLabel="" :show-labels="false"
                                     placeholder="Select Logo file"/>
                    </div>
                </div>
                <div class="row input_lines align-items-center">
                    <div class="col-6 main_text label_title">
                        Add Watermarks
                        <span @click="openModalAddFile" class="upload-files-text">(Add new file)</span>
                    </div>
                    <div class="col-6 required_select">
                        <multiselect v-model="requirements.add_watermark"
                                     track-by="id" label="id" :options="user_files"
                                     :custom-label="customLabel"
                                     selectLabel="" deselectLabel="" :show-labels="false"
                                     placeholder="Select Watermark File"/>
                    </div>
                </div>
                <div class="row input_lines align-items-center mt-5">
                    <div class="col-6 main_text label_title">
                        Video instruction
                        <span @click="openModalAddFile" class="upload-files-text">(Add new file)</span>
                    </div>
                    <div class="col-6 required_select">
                        <div class="d-flex justify-content-between mb-3">
                            <span>
                                <input type="radio" id="video_1" class="form-check-input" value="sample_works" v-model="requirements.video_instruction_type">
                                <label for="video_1" class="radio-grid-selector ms-2" :class="{'active':requirements.video_instruction_type==='sample_works'}">Sample works</label>
                            </span>
                            <span>
                                <input type="radio" id="video_2" class="form-check-input" value="files" v-model="requirements.video_instruction_type">
                                <label for="video_2" class="radio-grid-selector ms-2" :class="{'active':requirements.video_instruction_type==='files'}">Assets</label>
                            </span>
                        </div>
                        <multiselect v-if="requirements.video_instruction_type==='files'"
                                     v-model="requirements.file_video_instruction_id"
                                     track-by="id" label="id" :options="user_files"
                                     :custom-label="customVideoInstructionsLabel"
                                     selectLabel="" deselectLabel="" :show-labels="false"
                                     placeholder="Select Instruction File"/>
                        <multiselect v-else v-model="requirements.file_video_instruction_id"
                                     track-by="id" label="id" :options="sample_works"
                                     group-label="name"
                                     group-values="sample_works"
                                     :custom-label="customVideoInstructionsLabel"
                                     selectLabel="" deselectLabel="" :show-labels="false"
                                     placeholder="Select Instruction File"/>
                    </div>
                </div>
                <div class="row input_lines align-items-center">
                    <div class="col-6 main_text label_title">
                        Color palette
                        <span @click="openModalAddFile" class="upload-files-text">(Add new file)</span>
                    </div>
                    <div class="col-6 required_select">
                        <multiselect v-model="requirements.file_id_color_palette"
                                     track-by="id" label="id" :options="user_files"
                                     :custom-label="customLabel"
                                     selectLabel="" deselectLabel="" :show-labels="false"
                                     placeholder="Select Color palette"/>
                    </div>
                </div>
                <div class="row input_lines align-items-center">
                    <div class="col-6 main_text label_title">
                        Typography
                        <span @click="openModalAddFile" class="upload-files-text">(Add new file)</span>
                    </div>
                    <div class="col-6 required_select">
                        <multiselect v-model="requirements.file_id_typography"
                                     track-by="id" label="id" :options="user_files"
                                     :custom-label="customLabel"
                                     selectLabel="" deselectLabel="" :show-labels="false"
                                     placeholder="Select Typography"/>
                    </div>
                </div>
                <div class="row input_lines align-items-center">
                    <div class="col-6 main_text label_title">
                        Red Eye
                    </div>
                    <div class="col-6 section_checkbox">
                        <div class="input_checkbox cp" @click="checkedItem('red_eye')">
                            <div class="checked_icon" v-if="requirements.checked_option.red_eye">&#10004;</div>
                        </div>
                    </div>
                </div>
                <div class="row input_lines align-items-center">
                    <div class="col-6 main_text label_title">
                        Invisible mannequin
                    </div>
                    <div class="col-6 section_checkbox">
                        <div class="input_checkbox cp" @click="checkedItem('invisible_mannequin')">
                            <div class="checked_icon" v-if="requirements.checked_option.invisible_mannequin">&#10004;</div>
                        </div>
                    </div>
                </div>
                <div class="row input_lines align-items-center">
                    <div class="col-6 main_text label_title">
                        Color matching
                    </div>
                    <div class="col-6 section_checkbox">
                        <div class="input_checkbox cp" @click="checkedItem('color_matching')">
                            <div class="checked_icon" v-if="requirements.checked_option.color_matching">&#10004;</div>
                        </div>
                    </div>
                </div>
                <div class="row input_lines align-items-center">
                    <div class="col-6 main_text label_title">
                        Creative color grading
                    </div>
                    <div class="col-6 section_checkbox">
                        <div class="input_checkbox cp" @click="checkedItem('color_grading')">
                            <div class="checked_icon" v-if="requirements.checked_option.color_grading">&#10004;</div>
                        </div>
                    </div>
                </div>
                <div class="row input_lines align-items-center">
                    <div class="col-6 main_text label_title">
                        Background removal
                    </div>
                    <div class="col-6 section_checkbox">
                        <div class="input_checkbox cp" @click="checkedItem('white_background')">
                            <div class="checked_icon" v-if="requirements.checked_option.white_background">&#10004;</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="text-end">Total price</div>
                <div class="total-price">
                    ${{price}}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-12">
                <textarea v-model="requirements.description" placeholder="Write a caption... " class="textarea" id="" :maxlength="max_description_length"/>
            </div>
            <div class="col-4 offset-8 col-lg-2 offset-lg-10 text-end">
                Characters left: {{max_description_length - requirements.description.length}}/{{max_description_length}}
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-12">
                <div class="row input_lines">
                    <div class="col-12 red_color label_title">
                        Required
                        <span class="star">*</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center gap-5 m_t_60 m_b_126" v-if="window_desktop">
            <div class="text-center">
                <button class="text-center custom_button white_background main_text" @click="goHome">
                    Cancel
                </button>
            </div>
            <div class=" text-center">
                <button class="text-center custom_button white_text custom_background" :class="validated ? '' : 'disabled_button'" :disabled="!validated" @click="nextTab">
                    Next
                </button>
            </div>
        </div>
        <AddFileModal ref="add_file_modal" @getProfileUser="getUserStyleGuide" />
    </div>
</template>

<script>
import {errorMessage} from "../../services/messages";
import {mapMutations} from "vuex";
import Multiselect from 'vue-multiselect';
import AddFileModal from "../Modals/AddFileModal";
import ('../../css/custom.css');

export default {
    name: 'AddRequirements',
    components: {AddFileModal, Multiselect },
    props: [
        'prices',
        'requirements',
        'validated_array',
        'errors',
        'user_plan',
        'user_discount',
        'getImagesNumber',
    ],
    data() {
        return {
            sizeW: null,
            window_desktop: true,
            validated: true,
            editing_levels: [
                'Simple',
                'Intermediate',
                'Advanced',
            ],
            style_guides: [],
            user_files: [],
            sample_works: [],
            color_profiles: [
                'RGB',
                'CMYK',
                'ICC',
            ],
            file_formats: [
                'PNG',
                'JPG',
                'JPEG',
                'TIFF',
                'GIF',
            ],
            max_description_length: 300,
        }
    },
    async mounted() {
        await this.getUserStyleGuide();
    },
    created() {
        window.addEventListener('resize', this.updateWidth);
        this.updateWidth();
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.updateWidth);
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
            'setUser',
        ]),
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async selectedStyleGuide() {
            this.requirements.video_instruction_type = this.requirements?.style_guide?.file_video_instruction_ids ? 'files' : 'sample_works';
            this.requirements.color_profile = this.requirements?.style_guide?.color_profile || '';
            this.requirements.file_format = [this.requirements?.style_guide?.file_format] || '';
            this.requirements.resolution = this.requirements?.style_guide?.resolution || '';
            this.requirements.resolution_units = this.requirements?.style_guide?.resolution_units || '';
            this.requirements.file_format = [this.requirements?.style_guide?.file_format] || '';
            this.requirements.other = this.requirements?.style_guide?.other || '';
            this.requirements.add_logo = this.user_files.find(el => el.id == this.requirements?.style_guide?.file_id_logo) || '';
            this.requirements.checked_option.white_background = this.requirements?.style_guide?.remove_background == 'Yes' ? true : false;
            this.requirements.add_watermark = this.user_files.find(el => el.id == this.requirements?.style_guide?.file_id_watermark) || '';
            this.requirements.file_id_color_palette = this.user_files.find(el => el.id == this.requirements?.style_guide?.file_id_color_palette) || '';
            this.requirements.file_id_typography = this.user_files.find(el => el.id == this.requirements?.style_guide?.file_id_video_typography) || '';

            if(this.requirements?.style_guide?.size_with && this.requirements?.style_guide?.size_height && this.requirements?.style_guide?.unit_measurement) {
                this.requirements.sizes.push({
                    width: this.requirements?.style_guide?.size_with,
                    height: this.requirements?.style_guide?.size_height,
                    unit: this.requirements?.style_guide?.unit_measurement
                });
            }

            await this.sleep(100);
            this.requirements.file_video_instruction_id = this.user_files.find(el => el.id == this.requirements?.style_guide?.file_video_instruction_ids) || '';
        },
        async getUserStyleGuide() {
            try {
                this.showLoader();
                const response = await this.$http.getAuth(`${this.$http.apiUrl()}profile/get-styles-job`);
                this.style_guides = response?.data?.data?.style_guides || [];
                this.user_files = response?.data?.data?.user_files || [];
                this.sample_works = response?.data?.data?.sample_works || [];
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        updateWidth() {
            this.sizeW = document.documentElement.clientWidth;
        },
        goHome() {
            this.$router.push({to: '/business-homepage', name: "BusinessHomePage"});
        },
        checkedItem(val) {
            this.requirements.checked_option[val] = !this.requirements.checked_option[val];
        },
        nextTab() {
            for (let i = 0; i < this.validated_array.length; i++) {
                let test = this.requirements[this.validated_array[i]] ?? null;
                if (test === '' || test === null || (Array.isArray(test) && test.length === 0)) {
                    this.validated = false;
                    this.errors[this.validated_array[i]] = true;
                    console.log(this.validated_array[i]);
                }
            }
            if (!this.validated) {
                return;
            }
            this.$emit('next-tab');
        },
        dropError() {
            for (let x = 0; x < this.validated_array.length; x++) {
                this.errors[this.validated_array[x]] = false;
            }
            this.validated = true;
        },
        customLabel({file_name}) {
            return `With "${ file_name }"`;
        },
        customVideoInstructionsLabel({file_name}) {
            return `With "${file_name}"`;
        },
        addSize() {
            this.requirements.sizes.push({
                width: '',
                height: '',
                unit: 'px'
            });
        },
        removeSize(index) {
            this.requirements.sizes.splice(index, 1);
        },
        openModalAddFile() {
            this.$refs.add_file_modal.load();
        },
    },
    computed: {
        price() {
            //level price + adds
            var price = (((this.prices.subscribes[this.user_plan]?.retouching[this.requirements.editing_level] || 0) + (this.user_plan == 'NO' ? (
                this.prices.adds.invisible_mannequin.price * this.requirements.checked_option.invisible_mannequin +
                this.prices.adds.color_matching.price * this.requirements.checked_option.color_matching +
                this.prices.adds.color_grading.price * this.requirements.checked_option.color_grading +
                this.prices.adds.white_background.price * this.requirements.checked_option.white_background +
                this.prices.adds.logo_watermarks.price * (this.requirements.add_logo ? 1 : 0) +
                this.prices.adds.logo_watermarks.price * (this.requirements.add_watermark ? 1 : 0) +
                this.prices.adds.size.price * (this.requirements.sizes.length > 0 ? this.requirements.sizes.length - 1 : 0) +
                this.prices.adds.format.price * (this.requirements.file_format.length > 0 ? this.requirements.file_format.length - 1 : 0)
            ) : 0)) * this.getImagesNumber / 100).toFixed(2) || 0;
            if(this.user_discount) {
                price = (price - (price * this.user_discount/100)).toFixed(2) || 0;
            }
            return price;
        }
    },
    watch: {
        sizeW(val) {
            this.window_desktop = val > 992;
        },
        'requirements.editing_level': {
            handler: function() {
                this.dropError();
            },
        },
        'requirements.style_guide': {
            handler: function() {
                this.selectedStyleGuide();
            },
        },
        'requirements.color_profile': {
            handler: function() {
                this.dropError();
            },
        },
        'requirements.file_format': {
            handler: function() {
                this.dropError();
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.upload-files-text {
    cursor: pointer;
    color: blue;
    font-size: 12px;
}
.section_checkbox, .checked_icon {
    display: flex;
    justify-content: center;
}
.main_text {
    color: #494949;
    font-style: normal;
}
.white_text {
    color: white;
    font-style: normal;
}
.item_body {
    width: 100%;
    min-height: 340px;
    padding: 11px;
}
.label_title {
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    padding-top: 5px;
}
.input_text_stile {
    width: 100%;
    height: 38px;
    border-radius: 19px;
    padding-left: 14px;
    padding-right: 14px;
    background: white;
    border: 0.3px solid #494949;
    box-sizing: border-box;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
    filter: opacity(0.35);
    overflow: hidden;
    text-overflow: ellipsis;
}
.input_checkbox {
    width: 22px;
    height: 22px;
    background: white;
    border: 0.3px solid #494949;
    filter: opacity(0.35);
    padding: 0 0 2px;
}
.input_lines {
    margin: 14px 0;
}
.custom_button {
    width: 200px;
    height: 60px;
    border: 0.5px solid #494949;
    box-sizing: border-box;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
}
.disabled_button {
    opacity: 0.45;
}
.required_select {
    position: relative;
}
.red_color {
    color: #bb2d3b;
}
.white_background {
    background: white;
}
.textarea {
    margin-top: 15px;
    width: 100%;
    height: 300px;
    resize: none;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    padding: 20px;
    background: white;
    border: 0.3px solid #494949;
    box-sizing: border-box;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
    filter: opacity(0.35);

    border-radius: 19px;
}
textarea::-webkit-input-placeholder {
    color: rgba(73, 73, 73, 0.45);
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
}
textarea:-moz-placeholder {
    color: rgba(73, 73, 73, 0.45);
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
}
.total-price {
    text-align: end;
    font-size: 38px;
    font-weight: 700;
}
.style_input1 {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    border: 0.3px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    height: 30px;
    width: 100%;
}
.x {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    padding: 7px;
}
.size-block {
    border: 1px solid rgba(74, 74, 74, 0.6);
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
