<template>
<div>
    <div v-if="this.is_preview === false">
        <button v-if="!window_desktop" class="text-center upload_button item_image" @click="chooseGDSource">
            <svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" viewBox="0 0 1443.061 1249.993">
                <path fill="#3777e3" d="M240.525 1249.993l240.492-416.664h962.044l-240.514 416.664z"/>
                <path fill="#ffcf63" d="M962.055 833.329h481.006L962.055 0H481.017z"/>
                <path fill="#11a861" d="M0 833.329l240.525 416.664 481.006-833.328L481.017 0z"/>
            </svg>
            <div class="text-center description_mobile main_text">
                Google Drive
            </div>
        </button>
        <button v-else class="text-center upload_button item_image" @click="chooseGDSource">
            <svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" viewBox="0 0 1443.061 1249.993">
                <path fill="#3777e3" d="M240.525 1249.993l240.492-416.664h962.044l-240.514 416.664z"/>
                <path fill="#ffcf63" d="M962.055 833.329h481.006L962.055 0H481.017z"/>
                <path fill="#11a861" d="M0 833.329l240.525 416.664 481.006-833.328L481.017 0z"/>
            </svg>
            <div class="text-center description_mobile main_text">
                Google Drive
            </div>
        </button>
    </div>
    <div v-if="this.is_preview === true">
        <button v-if="!window_desktop" class="text-center item_image" @click="chooseGDSource">
            <svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" viewBox="0 0 1443.061 1249.993">
                <path fill="#3777e3" d="M240.525 1249.993l240.492-416.664h962.044l-240.514 416.664z"/>
                <path fill="#ffcf63" d="M962.055 833.329h481.006L962.055 0H481.017z"/>
                <path fill="#11a861" d="M0 833.329l240.525 416.664 481.006-833.328L481.017 0z"/>
            </svg>
            <div class="text-center description_mobile main_text">
                Google Drive
            </div>
        </button>
        <button v-else class="text-center item_image" @click="chooseGDSource">
            <svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" viewBox="0 0 1443.061 1249.993">
                <path fill="#3777e3" d="M240.525 1249.993l240.492-416.664h962.044l-240.514 416.664z"/>
                <path fill="#ffcf63" d="M962.055 833.329h481.006L962.055 0H481.017z"/>
                <path fill="#11a861" d="M0 833.329l240.525 416.664 481.006-833.328L481.017 0z"/>
            </svg>
            <div class="text-center description_mobile main_text">
                Google Drive
            </div>
        </button>
    </div>
    <div class="modal fade modal-google-drive pb-5" ref="gd_modal" id="google-drive-files-list" tabindex="-1" aria-labelledby="google-drive-files-list" aria-hidden="true">
        <div class="modal-dialog modal-google-drive-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button ref="folder_back" type="button" data-previous-folder="/" class="btn-folder-back" aria-label="Back" @click="GDBackClick">
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 26.676 26.676" style="enable-background:new 0 0 26.676 26.676;" xml:space="preserve">
                            <g><path d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59 c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815 C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029 c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562 C26.18,21.891,26.141,21.891,26.105,21.891z"/>
                                <g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/>
                        </svg>
                    </button>
                    <h5 class="modal-title red_color" id="errorModalLabel">Google Drive Files List</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                </div>
                <div class="modal-body modal-google-drive-body" ref="google_drive_modal_body">
                    <div class="row gd-item-row"
                         v-for="(gdfile, key, index) in google_drive_file_list"
                         :data-file-name="index"
                         :data-file-display-path="gdfile.display_path"
                         :class = "(gdfile.type === 'dir') ? 'directory-row' : 'image-row'"
                         :key="key"
                         @click="() => (gdfile.type === 'dir') ? GDFolderClick(gdfile.display_path) : GDImageClick(gdfile.display_path)">
                        <div class="col-1 d-flex justify-content-center">
                            <svg v-if="gdfile.type === 'dir'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-folder2" viewBox="0 0 16 16">
                                <path d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v7a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 12.5v-9zM2.5 3a.5.5 0 0 0-.5.5V6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5zM14 7H2v5.5a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5V7z"/>
                            </svg>
                            <svg v-if="gdfile.type === 'file'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-image-fill" viewBox="0 0 16 16">
                                <path d="M4 0h8a2 2 0 0 1 2 2v8.293l-2.73-2.73a1 1 0 0 0-1.52.127l-1.889 2.644-1.769-1.062a1 1 0 0 0-1.222.15L2 12.292V2a2 2 0 0 1 2-2zm4.002 5.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z"/>
                                <path d="M10.564 8.27 14 11.708V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-.293l3.578-3.577 2.56 1.536 2.426-3.395z"/>
                            </svg>
                        </div>
                        <div class="col-11">
                            <p>{{gdfile.filename}}</p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button v-if="this.is_authorized" @click="detachGD" type="button" class="btn btn-danger">Detach GoogleDrive</button>
                    <button v-else @click="isGDAuth" type="button" class="btn btn-primary">Attach GoogleDrive</button>
                    <button type="button" class="close_modal main_text" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {Modal} from "bootstrap";
import {mapMutations} from "vuex";
import helpFunctions from "../../helpFunctions";
const axios = require('axios');

export default {
    name: 'AddGoogleDriveFile',
    props: [
        "window_desktop",
        "is_preview",
    ],
    data() {
        return {
            google_drive_file_list: {},
            gd_modal: null,
            folder_back: null,
            is_authorized: false,
        }
    },
    mounted() {
        this.gd_modal = new Modal(this.$refs.gd_modal);
        addEventListener('message', this.postmessage);
    },
    unmounted() {
        removeEventListener('message', this.postmessage);
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
        ]),
        postmessage(e) {
            if (e.data === 'GoogleDrive authorization') {
                this.isGDAuth();
            }
        },
        isGDAuth() {
            this.$http.getAuth(`${this.$http.apiUrl()}google_drive/is_authorized`)
                .then(response => {
                    let isAuthorized = response?.data?.authorized;
                    if (isAuthorized !== undefined && !isAuthorized) {
                        let redirectUrl = response?.data?.redirect_url?.original?.data;
                        window.open(redirectUrl, "_blank");
                    } else {
                        this.is_authorized = true;
                        this.GDFolderClick("/");
                    }
                });
        },
        emptyModal() {
            this.google_drive_file_list = "";
        },
        detachGD() {
            this.showLoader();
            this.$http.getAuth(`${this.$http.apiUrl()}google_drive/detach`)
                .then( response => {
                    this.is_authorized = false;
                    this.emptyModal();
                })
                .finally(() => this.hideLoader());
        },
        GDBackClick() {
            let folder_back_obj = this.$refs.folder_back;
            let previous_folder = folder_back_obj.getAttribute("data-previous-folder");
            this.GDFolderClick(previous_folder);
        },
        GDFolderClick(file_path) {
            let previous_folder = file_path.substring(0, file_path.lastIndexOf('/'));
            let folder_back_obj = this.$refs.folder_back;
            folder_back_obj.setAttribute("data-previous-folder", previous_folder);
            this.showLoader();
            this.$http.getAuth(`${this.$http.apiUrl()}google_drive/files/list?folderId=${file_path}`)
                .then(response => {
                    this.google_drive_file_list = Object.assign({}, response.data);
                    }
                )
                .finally(() => this.hideLoader());
        },
        GDImageClick: function(file_path) {
            this.showLoader();
            axios.get(`${this.$http.apiUrl()}google_drive/files/get?file_path=${file_path}`,
                {
                    headers: { 'Authorization': `Bearer ${localStorage.getItem('strobeart_jwt')}` },
                    responseType: 'blob'
                })
                .then(response => {
                    let content_type = response.headers["content-type"];
                    let extension = content_type.split("/")[1];
                    let image_name = helpFunctions.makeId(24);
                    let file = new File([response.data], `${image_name}.${extension}`);

                    const reader = new FileReader();
                    reader.onload = e => {
                        let src;
                        if (file.type.toLowerCase()==='image/tiff' || file.type.toLowerCase()==='image/tif') {
                            try {
                                let tiff = new Tiff({buffer: reader.result});
                                let canvas = tiff.toCanvas();
                                src = canvas.toDataURL();
                            } catch (e) {
                                src = 'tif';
                            }
                        }
                        else if (file.type.toLowerCase()==='image/gif' || file.type.toLowerCase()==='image/webp' || file.type.toLowerCase()==='image/bmp' ||
                            file.type.toLowerCase()==='image/jpeg' || file.type.toLowerCase()==='image/jpg' || file.type.toLowerCase()==='image/png') {
                            src = reader.result;
                        }
                        else {
                            src = "";
                        }
                        this.$emit("include_image",
                            {
                                "image": {
                                    src: src,
                                    name: file.name,
                                },
                                "file": file,
                            });
                    }
                    if (file.type.toLowerCase()==='image/tiff' || file.type.toLowerCase()==='image/tif') {
                        reader.readAsArrayBuffer(file);
                    }
                    else {
                        reader.readAsDataURL(file);
                    }

                    /*const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = e => {
                        this.$emit("include_image",
                            {
                                "image": {
                                    src: reader.result,
                                    name: file.name,
                                },
                                "file": file,
                            });
                    }*/
                })
                .then(response => {
                    this.gd_modal.toggle();
                })
                .finally(() => this.hideLoader());
        },
        chooseGDSource () {
            this.isGDAuth();
            if(this.gd_modal) {
                this.gd_modal.toggle();
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.main_text {
    color: #494949;
    font-style: normal;
}
.gd-item-row {
    cursor: pointer;
}
.item_image {
    width: 102px;
    height: 102px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3px;
    margin: 3px 0;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15) !important;
    box-sizing: border-box;
    border-radius: 5px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .delete_image_button {
        position: absolute;
        top: 0;
        right: 0;
        background: red;
        color: white;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: none;
        padding-bottom: 3px;

        span {
            position: absolute;
            top: 1px;
            right: 7px;
            font-style: normal;
        }
    }
}
.description_mobile {
    font-weight: 500;
    font-size: 12.5px;
    line-height: 20px;
}
.upload_button {
    width: 249px;
    height: 272px;
    background: white;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15) !important;
    border: none;
    margin: 0 auto;
    @media only screen and (max-width: 992px) {
        margin-top: 15px;
        margin-bottom: 15px;
        width: 120px;
        height: 120px;
    }
}
.btn-folder-back {
    background-color: #e0e0e0;
    border-radius: 30px;
    border: none;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    margin-right: 20px;
}
.close_modal {
    width: 120px;
    height: 50px;
    background: white;
    border: 0.5px solid #494949;
    box-sizing: border-box;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
}
</style>
