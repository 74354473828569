<template>
    <div class="item_body">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="in_center" :class="window_desktop ? 'width_450' : 'width_300'">
                    <DatePicker
                        :locale="{id: 'en', masks: {weekdays: 'WW'}}"
                        mode='range'
                        v-model='date'
                        :is-expanded = "true"
                        title-position="left"
                        @dayclick="onDayClick"
                        :min-date="(new Date()).setDate((new Date()).getDate()+1)"
                        :max-date="(new Date()).setDate((new Date()).getDate()+6)"
                        show-caps/>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center gap-5 m_t_70 m_b_126" v-if="window_desktop">
            <div class="text-center">
                <button class="text-center custom_button white_background main_text" @click="goHome">
                    Cancel
                </button>
            </div>
            <div class="text-center">
                <button class="text-center custom_button white_text custom_background" :class="this.dates.due_date ? '' : 'disabled_button'" :disabled="!this.dates.due_date" @click="$emit('submit-form')">
                    Next
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Calendar from 'v-calendar/lib/components/calendar.umd';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { setupCalendar} from 'v-calendar';
import moment from "moment-timezone";
import ('../../css/custom.css');

setupCalendar({
    componentPrefix: 'vc',
});

export default {
    name: 'AddDueDate',
    components: {
        Calendar,
        DatePicker,
    },
    props: [
        'dates'
    ],
    data() {
        return {
            sizeW: null,
            window_desktop: true,
            date: null,
        }
    },
    created() {
        window.addEventListener('resize', this.updateWidth);
        this.updateWidth();
        this.date = moment().add(1, 'days').format('YYYY-MM-DD');
        this.dates.due_date = moment().add(1, 'days').format('YYYY-MM-DD');
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.updateWidth);
    },
    methods: {
        updateWidth() {
            this.sizeW = document.documentElement.clientWidth;
        },
        goHome() {
            this.$router.push({to: '/business-homepage', name: "BusinessHomePage"});
        },
        onDayClick() {
            this.dates.due_date = this.date;
        },
    },
    watch: {
        sizeW(val) {
            this.window_desktop = val > 992;
        },
    }
}
</script>

<style>
.is-disabled {
    pointer-events: none;
}
</style>
<style lang="scss" scoped>
.main_text {
    color: #494949;
    font-style: normal;
}
.white_text {
    color: white;
    font-style: normal;
}
.item_body {
    width: 100%;
    min-height: 340px;
    padding: 11px;
}
.custom_button {
    width: 200px;
    height: 60px;
    border: 0.5px solid #494949;
    box-sizing: border-box;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
}
.white_background {
    background: white;
}
.disabled_button {
    opacity: 0.45;
}
.width_450 {
    width: 450px;
}
.width_300 {
    width: 300px;
}
.in_center {
    margin: 0 auto;
}
</style>
